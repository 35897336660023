<!--
 * @Author: shmily
 * @Date: 2021-11-15 09:36:10
 * @LastEditTime: 2021-11-15 09:37:16
 * @LastEditors: shmily
 * @Description: 
 * @FilePath: \nihaokidsNew-front\src\components\Course\Interaction\NewImg\index.vue
-->

<!--
  ATTR
    src        图片地址
    objectFit     同 object-fit
    clickImg        点击图片预览全图
-->
<template>
  <div class="new_img">
    <img
      v-show="src && !imgerror"
      ref="img"
      :src="src"
      :style="setImgstyle()"
      @error="imgError"
      @click="imgClick"
      @load="imgLoad"
      @contextmenu="imageContextmenu"
    />
    <!-- <img v-show="imgerror" class="errorImage" :src="require('@/common/img/image.png')"> -->
  </div>
</template>

<script>
// import this.$bus from "@/common/js/eventCenter.js";
export default {
  props: {
    src: {
      type: String,
      default: () => "",
    },
    objectFit: {
      type: String,
      default: () => "contain",
    },
    clickImg: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      imgerror: true,
    };
  },
  methods: {
    setImgstyle() {
      let style = {};
      style.objectFit = this.objectFit;
      return style;
    },
    // 图片右键
    imageContextmenu(event) {
      event.preventDefault();
    },
    // 图片加载成功
    imgLoad() {
      this.imgerror = false;
    },
    // 图片加载失败
    imgError() {
      this.imgerror = true;
    },
    // 图片点击,只有clickImg为true时生效
    imgClick() {
      if (this.clickImg) {
        this.$bus.$emit("clickImgSrc", this.src);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.new_img {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
    height: 100%;
  }
  .errorImage {
    object-fit: cover;
  }
}
</style>
