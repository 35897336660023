var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new_img" }, [
    _c("img", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.src && !_vm.imgerror,
          expression: "src && !imgerror",
        },
      ],
      ref: "img",
      style: _vm.setImgstyle(),
      attrs: { src: _vm.src },
      on: {
        error: _vm.imgError,
        click: _vm.imgClick,
        load: _vm.imgLoad,
        contextmenu: _vm.imageContextmenu,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }